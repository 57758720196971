import HomePage from "./page/homePage/HomePage";
import "./app.css";

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
