import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 182px;
  padding-bottom: 80px;
  gap: 37px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(224, 251, 224, 1)
  );

  @media (max-width: 1000px) {
    padding: 150px 20px;
  }
  @media (max-width: 660px) {
    padding: 50px 20px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 862px;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.h5`
  font-size: 48px;
  font-weight: 400;
  line-height: 57px;
  color: #323232;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  @media (max-width: 1000px) {
    font-size: 35px;
    line-height: 40px;
  }
  @media (max-width: 660px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const SubTitle = styled.h3`
  font-size: 64px;
  font-weight: 700;
  line-height: 96px;
  color: #323232;
  font-family: "HelveticaNeue", Arial, sans-serif;

  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 80px;
  }
  @media (max-width: 660px) {
    font-size: 45px;
    line-height: 75px;
  }
`;
export const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 12px;
  color: #7f7f7f;
  @media (max-width: 1000px) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (max-width: 660px) {
    font-size: 13px;
    line-height: 23px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
