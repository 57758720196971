import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 24px;
  width: 100%;
  max-width: 1300px;
  display: grid;
  margin: 0 auto;
  margin-top: 70px;
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const Grid = styled.div`
  margin-top: 68px;
  margin-bottom: 70px;
  padding: 0 24px;
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  justify-self: center;
  @media (max-width: 1280px) {
    margin: 68px 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1000px) {
    margin: 680px 0;

    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 680px) {
    margin: 175px 0;
    grid-template-columns: 1fr;
  }
`;
export const Image = styled.img`
  max-width: 148px;
  max-height: 50px;
`;

export const Card = styled.div`
  border: 1px solid "linear-gradient(180deg, #F0FFF5 0%, #F8FFFA 100%)";
  display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  /* padding: 48px 40px 24px; */
  position: relative;
  background: ${(props) => {
    if (props.backgroundcolor === "green") {
      return "linear-gradient(180deg, #F0FFF5 0%, #F8FFFA 100%)";
    } else if (props.backgroundcolor === "red") {
      return "linear-gradient(180deg, #FED3DC 0%, #FEEDF0 100%)";
    }
    return "none";
  }};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 45px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    padding: 1px;
    background: ${(props) =>
      props.backgroundcolor === "green"
        ? "linear-gradient(180deg, rgba(26, 159, 65, 0.2) 0%, rgba(26, 159, 65, 0.1) 100%)"
        : props.backgroundcolor === "red"
        ? "linear-gradient(180deg, rgba(223, 28, 65, 0.2) 0%, rgba(223, 28, 65, 0.1) 100%)"
        : "linear-gradient(180deg, rgba(168, 176, 170, 0.2) 0%, rgba(168, 176, 170, 0.1) 100%)"};
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    z-index: -1;
  }
`;

export const StatusContainer = styled.div`
  width: fit-content;
  background-color: #cdf1dc;
  padding: 6px 14px;
  color: #1a9f41;
  border-radius: 0 0 10px 10px;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
`;

export const Title = styled.div`
  font-family: Helvetica Neue LT GEO;
  font-size: 18px;
  line-height: 16px;
  margin-top: 12px;
  margin-bottom: 56px;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 90px;
`;

export const SearchTitle = styled.div`
  font-family: "HelveticaNeue-normal", Arial, sans-serif;
  text-align: center;
  font-size: 20px;
`;

export const SearchDescription = styled.div`
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
`;

export const LabelsContainer = styled.div`
  margin-top: 16px;
  padding: 4px;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const LabelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const LabelColorGreen = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 4px;
  background-color: #1a9f41;
`;
export const LabelColorGray = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 4px;
  background-color: #7a7a7a;
`;
export const LabelColorRed = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 4px;
  background-color: #df1c41;
`;

export const LabelText = styled.div`
  font-size: 12px;
`;
