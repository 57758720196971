import styled from "styled-components";

export const Wrapper = styled.button`
  padding: 18px 40px;
  border-radius: 40px;
  border: none;
  font-family: "HelveticaNeue-normal", Arial, sans-serif;

  color: ${(props) => (props.type === "green" ? "#FFFFFF" : "#323232")};
  background-color: ${(props) =>
    props.type === "green" ? "#41AD49" : "#FFFFFF"};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.type === "green" ? "#4BBF54" : "##FAFAFA"};
    color: ${(props) => (props.type === "green" ? "#FFFFFF" : "#323232")};
  }
`;
