import React from "react";
import {
  Wrapper,
  InfoContainer,
  Grid,
  Card,
  Title,
  Header,
  IconWrapper,
  Image,
  SearchContainer,
  SearchTitle,
  SearchDescription,
  LabelsContainer,
  LabelItem,
  LabelColorGreen,
  LabelText,
  LabelColorGray,
  LabelColorRed,
  StatusContainer,
} from "./GridSection.style";
import CardComponent from "../cardComponent/CardComponent";
import { ReactComponent as Support } from "../../assets/icons/support.svg";
import { ReactComponent as Thinking } from "../../assets/icons/thinking.svg";
import { ReactComponent as NotSupport } from "../../assets/icons/notSupport.svg";

const Status = {
  OUR: 1,
  THEIR: 2,
  NEUTRAL: 3,
};

const GridSection = ({ data }) => {
  return (
    <Wrapper>
      <InfoContainer>
        <CardComponent
          icon={<Support />}
          title="მხარდამჭერი"
          quantity={
            data.businesses.filter((item) => item.freeStatus === Status.OUR)
              .length
          }
        />
        <CardComponent
          icon={<Thinking />}
          title="არ დაუფიქსირებია"
          quantity={
            data.businesses.filter((item) => item.freeStatus === Status.NEUTRAL)
              .length
          }
        />
        <CardComponent
          icon={<NotSupport />}
          title="წინააღმდეგი"
          quantity={
            data.businesses.filter((item) => item.freeStatus === Status.THEIR)
              .length
          }
        />
      </InfoContainer>
      <SearchContainer>
        <SearchTitle>კომპანიების ჩამონათვალი</SearchTitle>
        <SearchDescription>
          თქვენ აქ იხილავთ იმ კომპანიებს რომელთა პოზიციაც დაფიქსირებულია ჩვენი
          მიზნის მიმართ
        </SearchDescription>
        <LabelsContainer>
          <LabelItem>
            <LabelColorGreen />
            <LabelText>მხარდამჭერი</LabelText>
          </LabelItem>
          <LabelItem>
            <LabelColorGray />
            <LabelText>არ დაუფიქსირებია</LabelText>
          </LabelItem>
          <LabelItem>
            <LabelColorRed />
            <LabelText>წინააღმდეგია</LabelText>
          </LabelItem>
        </LabelsContainer>
      </SearchContainer>
      <Grid>
        {data.businesses
          .filter((item) => item.freeStatus === Status.OUR)
          .map((item, index) => (
            <Card backgroundcolor={"green"} key={index}>
              <StatusContainer>მხარდამჭერი</StatusContainer>
              <Header>
                <Image src={item.logoEndPointUrl} />
                <Title>{item.businessName}</Title>
              </Header>
            </Card>
          ))}
      </Grid>
    </Wrapper>
  );
};

export default GridSection;
