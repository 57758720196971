import React from "react";
import { Wrapper, IconWrapper, Text, Quantity } from "./CardComponent.style";

const CardComponent = ({ icon, title, quantity }) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Text>{title}</Text>
      <Quantity>{quantity}</Quantity>
    </Wrapper>
  );
};

export default CardComponent;
