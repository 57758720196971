import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  flex: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 12px;
  background-color: #eeeeee;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const Text = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const Quantity = styled.div`
  font-size: 42px;
  font-family: "HelveticaNeue", Arial, sans-serif;
`;
