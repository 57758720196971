import React from "react";
import { Footer, Header, Overlay, Title, Wrapper } from "./Join.style";
import { isMobile } from "react-device-detect";
import Button from "../../shared/button/Button";

const Join = ({ size, onClose }) => {
  return (
    <>
      <Overlay onClick={onClose} />
      <Wrapper isMobile={isMobile} size={size}>
        <Header size={size}>
          <Title>შემოუერთდი</Title>
          <span>X</span>
        </Header>
        <Footer>
          <Button type="green" content="შეუერთდი" />
        </Footer>
      </Wrapper>
    </>
  );
};

export default Join;
