import React from "react";
import {
  Wrapper,
  ContentContainer,
  HeaderWrapper,
  LogoWrapper,
  Title,
  SubTitle,
  Description,
  ButtonContainer,
} from "./Information.style";
import Button from "../../shared/button/Button";
import { ReactComponent as Flags } from "../../assets/icons/flags.svg";

const Information = () => {
  return (
    <Wrapper>
      <ContentContainer>
        <LogoWrapper>
          <Flags />
        </LogoWrapper>
        <HeaderWrapper>
          <Title>თავისუფალი</Title>
          <SubTitle>ბიზნესების განცხადება</SubTitle>
        </HeaderWrapper>
        <Description>
          არსებული კრიზისი გასცდა პარტიული პოლიტიკის საზღვრებს, რაც გარდაუვალი
          სოციალური და ეკონომიკური საფრთხის წინაშე აყენებს ქვეყანას. აუცილებელია
          ვითარების რაც შეიძლება სასწრაფო დეესკალაცია. დეესკალაციის ყველაზე
          პრაგმატული გზა არის ახალი არჩევნების დანიშვნა და ბოლო პროცესების
          განმავლობაში დაპატიმრებული ადამიანების ახალ წლამდე
          განთავისუფლება.ჩვენ, ქართული ბიზნესის წარმომადგენლები, ვართ რა
          პასუხისგებელნი ქვეყნის ბედზე და მის შეუქცევად განვითარებაზე,
          განურჩევლად ჩვენი პოლიტიკური პრეფერენციებისა ხელს ვაწერთ ამ მოწოდებას
          და განცხადებას
        </Description>
      </ContentContainer>
      <ButtonContainer>
        <Button type="green" content="შეუერთდი" />
        <Button type="white" content="მოითხოვე კომპანიის პოზიცია" />
      </ButtonContainer>
    </Wrapper>
  );
};

export default Information;
