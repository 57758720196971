import React, { useState, useEffect } from "react";
import Information from "../../components/information/Information";
import GridSection from "../../components/gridSection/GridSection";
import Join from "../../components/join/Join";

const HomePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://prod-freebusiness-loadbalancer-868115444.eu-central-1.elb.amazonaws.com/Core/Business/GetAll"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the fetch runs only once

  if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;
  return (
    <>
      <Information />
      <GridSection data={data} />

      {/* <Join /> */}
    </>
  );
};

export default HomePage;
