import styled, { css } from "styled-components";

// Reusable styles
const fixedPositionStyles = css`
  position: fixed;
  z-index: 100;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  border-radius: 12px;
`;

const sizeStyles = {
  medium: css`
    max-width: 768px;
    padding: 20px 24px;
  `,
  small: css`
    max-width: 528px;
    padding: 18px 24px;
  `,
};

// Wrapper Component
export const Wrapper = styled.div(
  ({ size, isMobile, theme }) => css`
    ${fixedPositionStyles};
    width: ${isMobile ? "93%" : "100%"};
    ${sizeStyles[size]}
  `
);

// Overlay Component
export const Overlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    opacity: 0.5;
    z-index: 99;
  `
);

// Header Component
export const Header = styled.div(
  ({ size }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(101, 101, 101, 0.08);
    ${sizeStyles[size]}
  `
);

// Title Component
export const Title = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    line-height: normal;
  `
);

// Icon Wrapper Component
export const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Footer Component
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(101, 101, 101, 0.08);
  padding: 12px 24px;
`;
